<template>
  <div>
    <div class="d-flex flex-row mb-6">
      <p class="text-2xl mb-6">
        GA view IDs pour le département
        <!--span class="text-uppercase">
          {{ selectedDepartmentShortname }}
        </span-->
      </p>

      <v-autocomplete
        outlined
        v-model="selectedDepartmentId"
        :items="departmentList"
        :menu-props="{ maxHeight: '400', zIndex: 13 }"
        label="Département"
        item-text="name"
        item-value="id"
        class="select-department ml-16 shrink"
        @change="getData"
      ></v-autocomplete>
    </div>

    <v-text-field
      v-model="searchStr"
      label="Search"
      @input="search"
      dense
    ></v-text-field>
    <div v-if="filteredData.length !== 0">
      <v-simple-table dense>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Id</th>
              <th class="text-left">Nom du site (id)</th>
              <th class="text-left">Domaine</th>
              <th class="text-left">GA View Id par défaut</th>
              <th class="text-left">
                CRM GA View Id
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <span
                      v-bind="attrs"
                      v-on="on"
                      v-html="`<span>*<sup>?</sup><span>`"
                    ></span>
                  </template>
                  <span>
                    Les GA viewID spécifique en rouges montrent qu'ils sont en
                    fait identiques à l'original, donc inutiles.
                  </span>
                </v-tooltip>
              </th>
              <th class="text-left">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, i) in filteredData"
              :key="item.department_site_ga_view_id_id"
            >
              <td>{{ item.department_site_ga_view_id_id }}</td>
              <td>
                <b class="primary--text"
                  >{{ item.name }} ({{ item.site_id }})</b
                >
              </td>
              <td>{{ item.domain }}</td>
              <td>{{ item.default_ga_view_id }}</td>
              <td>
                <div
                  v-if="i == selectedIndex"
                  class="d-flex justify-space-around"
                >
                  <v-text-field
                    v-model="item.input"
                    label="Department GA view id"
                  ></v-text-field>
                  <v-btn
                    :data-index="i"
                    v-on:click="save"
                    color="primary"
                    small
                    elevation="4"
                    class="mx-2 align-self-center"
                  >
                    OK
                  </v-btn>
                  <v-btn
                    :data-index="i"
                    v-on:click="resetSelectedIndex"
                    color="primary"
                    icon
                    fab
                    x-small
                    elevation="4"
                    class="mx-2 align-self-center"
                  >
                    <v-icon>
                      {{ icons.mdiCancel }}
                    </v-icon>
                  </v-btn>
                </div>
                <b
                  v-else
                  :class="
                    item.default_ga_view_id == item.department_site_ga_view_id
                      ? 'red'
                      : 'primary--text'
                  "
                >
                  {{ item.department_site_ga_view_id }}
                </b>
              </td>
              <td>
                <div v-if="i != selectedIndex">
                  <v-btn
                    v-if="
                      !item.department_site_ga_view_id_id ||
                      item.department_site_ga_view_id == '' ||
                      item.department_site_ga_view_id == null
                    "
                    :data-index="i"
                    v-on:click="edit"
                    color="primary"
                    icon
                    fab
                    x-small
                    elevation="4"
                    class="mx-2"
                  >
                    <v-icon>
                      {{ icons.mdiPlus }}
                    </v-icon>
                  </v-btn>
                  <v-btn
                    v-else
                    :data-index="i"
                    v-on:click="edit"
                    color="primary"
                    icon
                    fab
                    x-small
                    elevation="4"
                    class="mx-2"
                  >
                    <v-icon>
                      {{ icons.mdiPencil }}
                    </v-icon>
                  </v-btn>
                  <v-btn
                    v-if="
                      item.department_site_ga_view_id_id &&
                      item.department_site_ga_view_id != '' &&
                      item.department_site_ga_view_id
                    "
                    :data-index="i"
                    v-on:click="deleteItem"
                    color="error"
                    icon
                    fab
                    x-small
                    elevation="4"
                    class="mx-2"
                  >
                    <v-icon>
                      {{ icons.mdiDelete }}
                    </v-icon>
                  </v-btn>
                </div>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <div v-else>
      {{ noDataMessage }}
    </div>
    <v-snackbar
      v-model="isSnackbarVisible"
      :timeout="2000"
      top
      :color="snackbarColor"
    >
      {{ snackbarText }}
    </v-snackbar>
    <v-dialog v-model="dialogConfirmVisible" max-width="800">
      <v-card>
        <v-card-title class="text-h5">
          {{ dialogConfirmTitle }}
        </v-card-title>
        <v-card-text>
          {{ dialogConfirmText }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" @click="dialogConfirmCallback">
            Oui
          </v-btn>
          <v-btn color="green darken-1" @click="dialogConfirmVisible = false">
            Abandonner
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { ref } from "@vue/composition-api";
import { mdiPlus, mdiPencil, mdiDelete, mdiCancel } from "@mdi/js";
import axios from "@axios";

let defaultNoDataMessage = "Retrieving data ...";

export default {
  name: "SitesGaViewId",
  setup() {
    const isSnackbarVisible = ref(false);
    return {
      isSnackbarVisible,
    };
  },
  async created() {
    this.departmentList = await this.$store.dispatch(
      "common/getDepartmentList"
    );

    if ("departmentShortname" in this.$route.params) {
      for (let i = 0; i < this.departmentList.length; i++) {
        if (
          this.departmentList[i]["shortname"] ==
          this.$route.params.departmentShortname
        ) {
          this.selectedDepartmentId = this.departmentList[i]["id"];
          this.selectedDepartmentShortname =
            this.departmentList[i]["shortname"];
          break;
        }
      }
    } else {
      this.selectedDepartmentId = this.departmentList[0]["id"];
    }
  },
  data() {
    return {
      departmentList: [],
      selectedDepartmentId: null, // 3 = CRM
      selectedDepartmentShortname: null, // 3 = CRM
      selectedIndex: null,
      selectedDatum: null,
      searchStr: "",
      searchTimeout: null,
      noDataMessage: defaultNoDataMessage,
      snackbarColor: "info", // info, primary, success, warning, error
      snackbarText: "",
      dialogConfirmVisible: false,
      dialogConfirmTitle: "",
      dialogConfirmText: "",
      dialogConfirmCallbackFunction: null,
      data: [
        /*
        {
          "parent_id": null,
          "name": "Vie pratique féminin",
          "code": "viepratique",
          "shortname": "vpf",
          "domain": "www.viepratique.fr",
          "default_ga_view_id": "165996786",
          "department_site_ga_view_id_id":1,
          "department_id": 3,
          "site_id": 1,
          "department_site_ga_view_id": 165996786
          },
        ...
        */
      ],
      icons: {
        mdiPlus,
        mdiPencil,
        mdiDelete,
        mdiCancel,
      },
      filteredData: [],
    };
  },
  methods: {
    resetSelectedIndex() {
      this.selectedIndex = null;
      this.selectedDatum = null;
    },
    async save(e) {
      this.selectedIndex = e.currentTarget.getAttribute("data-index");
      this.selectedDatum = this.filteredData[this.selectedIndex];
      console.log("Method save - this.selectedIndex", this.selectedIndex);
      console.log("Method save - this.selectedDatum", this.selectedDatum);
      const params = {
        id: this.selectedDatum.department_site_ga_view_id_id,
        department_id: this.selectedDepartmentId,
        site_id: this.selectedDatum.site_id,
        ga_view_id: this.selectedDatum.input,
      };
      const response = await axios.post(
        `/site_ga_view_ids/save_department_site_ga_view_id`,
        params
      );
      if (response.status === 201) {
        console.log("Method save - response", response);
        this.showSnackBar(
          `Specific department GA View ID "${this.selectedDatum.input}" stored successfully for ${this.selectedDatum.domain}.`,
          "success"
        );
        this.getData();
        this.resetSelectedIndex();
      } else {
        this.showSnackBar(`Une erreur est survenue`, "error");
      }
    },
    edit(e) {
      // :data-site_id="item.site_id"
      // :data-id="item.department_site_ga_view_id_id"
      // :data-department_site_ga_view_id="item.department_site_ga_view_id"
      this.selectedIndex = e.currentTarget.getAttribute("data-index");
      this.selectedDatum = this.filteredData[this.selectedIndex];
      console.log("Method edit - this.selectedIndex", this.selectedIndex);
      console.log("Method edit - this.selectedDatum", this.selectedDatum);
      if (
        this.selectedDatum.department_site_ga_view_id &&
        this.selectedDatum.department_site_ga_view_id != ""
      ) {
        this.selectedDatum.input =
          this.selectedDatum.department_site_ga_view_id;
      }
    },
    deleteItem(e) {
      const selectedIndex = e.currentTarget.getAttribute("data-index");
      const selectedDatum = this.filteredData[selectedIndex];
      const id = selectedDatum.department_site_ga_view_id_id;
      const department_site_ga_view_id =
        selectedDatum.department_site_ga_view_id;
      console.log(`delete(${id})`);
      this.showDialogConfirm(
        `Supprimer le GA view_id spécifique ${department_site_ga_view_id} ?`,
        "Après cette suppression, si un GA view ID par défaut existe pour ce site, il sera utilisé.",
        async () => {
          const { status } = await axios.delete(`/site_ga_view_ids/${id}`);
          if (status === 204) {
            this.showSnackBar(
              `Specific department GA View ID "${department_site_ga_view_id}" removed successfully.`,
              "success"
            );
            this.getData();
          } else {
            this.showSnackBar(`Une erreur est survenue`, "error");
          }
        }
      );
    },
    async getData() {
      this.data = [];
      this.filteredData = [];
      this.noDataMessage = defaultNoDataMessage;
      const url = "/site_ga_view_ids/by-department/3"; // 3 is the CRM department id
      console.log("url", url);
      const {
        data: { error, items },
      } = await axios.get(url, { params: {} });
      if (error !== undefined) {
        this.noDataMessage = error;
        return;
      }
      // JSON.parse(JSON.stringify(response.data));
      this.data = items;
      this.filteredData = items;
    },
    search() {
      console.log("search", this.searchStr);
      if (this.searchTimeout) {
        console.log("clearTimeout");
        clearTimeout(this.searchTimeout);
        this.searchTimeout = null;
      }
      setTimeout(() => {
        console.log("setTimeout end > filter");
        if (this.searchStr == "") {
          this.filteredData = this.data;
          return;
        }
        const filteredData = [];
        const searchStrLC = this.searchStr.toLowerCase();
        for (let i = 0; i < this.data.length; i++) {
          console.log(this.data[i]["name"]);
          if (
            this.data[i]["name"].toLowerCase().includes(searchStrLC) ||
            this.data[i]["domain"].toLowerCase().includes(searchStrLC)
          ) {
            filteredData.push(this.data[i]);
          }
        }
        this.filteredData = filteredData;
      }, 500);
    },
    showSnackBar(text, color = "info") {
      this.snackbarColor = color; // info, primary, success, warning, error
      this.snackbarText = text;
      this.isSnackbarVisible = true;
    },
    dialogConfirmCallback() {
      this.dialogConfirmVisible = false;
      if (this.dialogConfirmCallbackFunction) {
        this.dialogConfirmCallbackFunction();
      }
      this.dialogConfirmCallbackFunction = null;
    },
    showDialogConfirm(title, text, callbackFunction) {
      this.dialogConfirmTitle = title;
      this.dialogConfirmText = text;
      this.dialogConfirmCallbackFunction = callbackFunction;
      this.dialogConfirmVisible = true;
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style scoped>
.select-year {
  width: 30px;
}
.select-year {
  width: 250px;
}
th {
  text-transform: capitalize;
  font-size: 11px;
}
.cell span {
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
td.cell span {
  font-size: 0.8em;
}
td.cell.value_title span,
td.cell.value_meta_description span {
  display: block;
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
td.cell.field_redac_name > span.more.redac-email {
  display: block;
  font-size: 0.8em;
}
td {
  z-index: 0;
}
.value_created,
.value_redac_name,
.value_title {
  position: sticky;
  width: 5em;
  left: 0;
  top: auto;
  z-index: 12;
  background-color: white;
}
b.red {
  color: red;
}
</style>
